import React, { ReactElement } from "react";

import _ from "lodash";
import { ConnectedProps, connect } from "react-redux";
import { AnyAction } from "redux";
import { ThunkDispatch } from "redux-thunk";

import { RootState } from "App/Store";

import * as constants from "Common/EVaultAppConstants";

import FilterBar from "Components/SearchBar/FilterBar";

import { QueryStringSearchKey } from "../Constants";
import {
  initialMinReconciliationReportSearchBarParams,
  resetSearch,
  updateSearchBarParams,
} from "./MinReconciliationSlice";
import { minReconciliationReportSearchParams } from "./MinReconciliationSlice";
import { executeSearch } from "./minReconciliationReportService";

const mapStateToProps = (state: RootState) => ({
  searchBarParams: state.minReconciliation.searchBarParams,
});

const connector = connect(mapStateToProps);

type TypesFromRedux = ConnectedProps<typeof connector>;

const searchCriteria = [
  ...constants.baseSearchCriteria,
  {
    name: "searchKey",
    options: [
      {
        apiParamValue: QueryStringSearchKey.ReconciliationReportId,
        description: "Report Id",
      },
    ],
    type: 0,
  },
];

export function MinReconciliationReportFilterBar(props: TypesFromRedux): ReactElement {
  const { searchBarParams, dispatch } = props;

  function handleReset() {
    dispatch(resetSearch());
  }

  function handleSubmit() {
    (dispatch as ThunkDispatch<any, undefined, AnyAction>)(executeSearch());
  }

  function handleCriteriaChange(value: minReconciliationReportSearchParams) {
    dispatch(updateSearchBarParams(value));
  }

  const displayResetButton = !_.isEqual(initialMinReconciliationReportSearchBarParams, searchBarParams);

  return (
    <FilterBar
      displayResetButton={displayResetButton}
      onCriteriaChange={handleCriteriaChange}
      onReset={handleReset}
      onSubmit={handleSubmit}
      searchCriterias={searchCriteria}
      searchParams={searchBarParams}
    />
  );
}

export default connector(MinReconciliationReportFilterBar);
