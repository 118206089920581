import axios from "axios";
import querystring from "querystring";
import { z } from "zod";

import { getAdjustedTimesFormatted } from "Common/dateUtilities";

import { IResultPages } from "Types/EVaultAppTypes";

import { MersDatabaseTransactionType } from "../VaultInterfaces";
import { IMinReconciliationReport } from "./MinReconciliationReportInterfaces";
import { IMinReconciliationReportsSearchApiParams } from "./MinReconciliationSlice";

const reportRoute = "/api/v1/mers/reconciliation-reports";

export interface IMinReconciliationReportsPagedData {
  links: IResultPages;
  results: IMinReconciliationReport[];
}

export function getMinReconciliationReportsByUrl(url: string): Promise<IMinReconciliationReportsPagedData> {
  return axios.get(url).then((response) => response.data);
}

interface IMinReconciliationReportsSearchPayload {
  searchTerm?: string;
  start?: string;
  stop?: string;
  limit: number;
  offset: number;
}

function getMinReconciliationReportsQueryString(params: IMinReconciliationReportsSearchApiParams) {
  const payload: IMinReconciliationReportsSearchPayload = {
    ...getAdjustedTimesFormatted(params.start, params.stop, true),
    limit: params.limit,
    offset: params.offset,
    searchTerm: params.searchTerm,
  };

  return "?" + querystring.stringify(payload as any);
}

export function getMinReconciliationReportsUrl(params: IMinReconciliationReportsSearchApiParams): string {
  return `${reportRoute}${getMinReconciliationReportsQueryString(params)}`;
}

/**
 * This interface represents the group status results within a reconciliation report.
 */
export interface IMersMinReconciliationReportStatus {
  count: number;
  transactionStatuses: Record<MersDatabaseTransactionType, { count: number }>;
}

/**
 * This interface represents the data that will be returned in reconciliation report status requests.
 */
export interface IMinReconciliationReportStatusResponse {
  count: number;
  groupStatuses: {
    failed?: IMersMinReconciliationReportStatus;
    inconsistent?: IMersMinReconciliationReportStatus;
  };
}

/**
 * Requests the status summary for the requested reconciliation report.
 */
export async function getMinReconciliationReportStatus(reportId: string) {
  return await axios.get<IMinReconciliationReportStatusResponse>(`${reportRoute}/${reportId}/status`);
}

export const mersOrgSchema = z.object({
  clientCode: z.string().length(4),
  mersOrgId: z.string().length(7).regex(new RegExp("^[0-9]*$"), "Only numeric characters are allowed"),
  name: z.string().min(1).max(100),
});

export type ReconciliationCreateClientMersOrg = z.infer<typeof mersOrgSchema>;

export type IReconciliationClientMersOrg = ReconciliationCreateClientMersOrg & {
  id: string;
};

const baseClientMersOrgUrl = "/api/v1/min-reconciliation-mersorgs";

function getClientClientBaseUrl(clientCode: string) {
  return `/api/v1/clients/${clientCode}/min-reconciliation-mersorgs`;
}

/**
 * Gets the client MERS orgs for MIN reconciliation.
 */
export async function getReconciliationClientMersOrgs() {
  return await axios.get<IReconciliationClientMersOrg[]>(baseClientMersOrgUrl);
}

/**
 * Adds the client mersOrg to the local list.
 */
export async function addClientMersOrg(clientCode: string, mersOrg: ReconciliationCreateClientMersOrg) {
  return axios.post(getClientClientBaseUrl(clientCode), mersOrg);
}

/**
 * Deletes the client mers orgs from the local list.
 */
export async function deleteClientMersOrg(clientMersOrgId: string, clientCode: string) {
  return axios.delete(`${getClientClientBaseUrl(clientCode)}/${clientMersOrgId}`);
}

/**
 * Updates the client mers orgs in the local list.
 */
export async function updateClientMersOrg(
  clientMersOrgId: string,
  mersOrgUpdate: ReconciliationCreateClientMersOrg,
  clientCode: string
) {
  return axios.put(`${getClientClientBaseUrl(clientCode)}/${clientMersOrgId}`, mersOrgUpdate);
}
