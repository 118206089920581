import React, { ReactElement } from "react";

import { ConnectedProps, connect } from "react-redux";
import { AnyAction } from "redux";
import { ThunkDispatch } from "redux-thunk";

import Link from "@mui/material/Link";

import Tooltip from "Components/Tooltip";

import { IMinReconciliationReport } from "./MinReconciliationReportInterfaces";
import { goToMinReconciliationReportDetailsTab } from "./minReconciliationReportService";

const connector = connect();
export interface IMinReconciliationReportColumnProps {
  minReconciliationReport: IMinReconciliationReport;
}

type TypesFromRedux = ConnectedProps<typeof connector>;
type Types = TypesFromRedux & IMinReconciliationReportColumnProps;

export function MinReconciliationReportIdColumn(props: Types): ReactElement {
  const { minReconciliationReport, dispatch } = props;
  function handleDispatchReconciliationReportDetails() {
    (dispatch as ThunkDispatch<any, undefined, AnyAction>)(
      goToMinReconciliationReportDetailsTab(minReconciliationReport)
    );
  }

  const classes = ["pseudolink"];
  return (
    <Tooltip title="Show Reconciliation Report Status">
      <Link className={classes.join(" ")} onClick={handleDispatchReconciliationReportDetails} role="button">
        {minReconciliationReport.name}
      </Link>
    </Tooltip>
  );
}

export default connector(MinReconciliationReportIdColumn);
