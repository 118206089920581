/* eslint-disable react/react-in-jsx-scope -- Unaware of jsxImportSource */

/** @jsxImportSource @emotion/react */
import React, { useState } from "react";

import { css } from "@emotion/react";
import { format } from "date-fns-tz";

import { Refresh } from "@mui/icons-material";
import { Box, Button, Grid } from "@mui/material";
import Typography from "@mui/material/Typography";

import BatchOperationsGroupStatus from "./BatchOperationsGroupStatus";
import { IMersBatch } from "./BatchOperationsInterfaces";
import { IMersBatchStatusResponse, getMersBatchStatus } from "./batchOperationsAdapter";

interface IMersBatchResults {
  batch: IMersBatch;
}

/**
 * Component for MERS batch results. This should contain
 */
export default function BatchOperationsDetails(props: IMersBatchResults): JSX.Element {
  const { batch } = props;
  const { id } = batch;
  const [data, setData] = React.useState<IMersBatchStatusResponse | undefined>(undefined);
  const [loading, setLoading] = React.useState<boolean>(true);
  const [totalCount, setTotalCount] = React.useState<number>(0);
  const [refreshDate, setRefreshDate] = useState<Date | undefined>();

  React.useEffect(() => {
    /**
     * Fetches the status for the batch.
     */
    async function getBatchStatus() {
      setLoading(true);
      const response = await getMersBatchStatus(id);
      setData(response.data);
      setTotalCount(response.data.count);
      setLoading(false);
    }
    void getBatchStatus();
  }, [id, refreshDate]);

  /**
   * Sets the date refresh date (forces a refresh of the status)
   */
  function handleRefresh() {
    setRefreshDate(new Date());
  }

  return (
    <div>
      <Box sx={{ display: "flex", justifyContent: "space-between", ml: 2, mr: 2, mt: 2 }}>
        <Typography variant="h5">
          {batch.name} -{" "}
          {format(new Date(batch.createdDate), "M/d/yyyy h:mm a zzz", {
            timeZone: "America/Boise",
          })}
        </Typography>

        <Button
          color="secondary"
          css={css`
            margin-left: 8px;
            min-width: 100px;
          `}
          onClick={handleRefresh}
          startIcon={<Refresh />}
          type="button"
          variant="contained"
        >
          REFRESH STATUS
        </Button>
      </Box>
      <Typography sx={{ ml: 2 }} variant="h6">
        All Results
      </Typography>
      <Grid container spacing={1} sx={{ ml: 1 }}>
        <BatchOperationsGroupStatus
          id={id}
          loading={loading}
          totalCount={totalCount}
          type="All"
          status={{ count: totalCount }}
        />
      </Grid>
      <Typography sx={{ ml: 2, mt: 1 }} variant="h6">
        Details
      </Typography>

      <Grid container spacing={1} sx={{ ml: 1 }}>
        <BatchOperationsGroupStatus
          id={id}
          loading={loading}
          status={data?.groupStatuses.complete}
          type="Complete"
          totalCount={totalCount}
        />
        <BatchOperationsGroupStatus
          id={id}
          loading={loading}
          status={data?.groupStatuses.inProgress}
          totalCount={totalCount}
          type="InProgress"
        />
        <BatchOperationsGroupStatus
          id={id}
          loading={loading}
          status={data?.groupStatuses.failed}
          totalCount={totalCount}
          type="Failed"
        />
        <BatchOperationsGroupStatus
          id={id}
          loading={loading}
          status={data?.groupStatuses.rejected}
          totalCount={totalCount}
          type="Rejected"
        />
      </Grid>
    </div>
  );
}
