import { endOfDay, isAfter, startOfDay } from "date-fns";
import { format as formatTz, toDate } from "date-fns-tz";

const formatDateOut = "yyyy-MM-dd HH:mm:ss";

export const DATE_MED = "MMM. dd, yyyy";
export const DATE_SHORT = "M/d/yyyy";
export const DATETIME_SORTABLE = "yyyyMMddHHmmss";
export const DATETIME_SHORT_WITH_OFFSET = "M/d/yyyy h:mm a z";

/**
 * Returns the formatted value for the Date or string
 * @param {Date | string | undefined} value The value we are formatting. If it is a string, it will be converted to the server time.
 * @param {string} timeFormat The time format to be used for formatting
 * @returns {string} the formatted string
 */
export function format(value: string | Date | undefined, timeFormat = DATE_SHORT): string {
  const defaultValue = "";
  if (value === undefined) return "";
  if (typeof value === "string") {
    if (value === "0001-01-01T00:00:00+00:00") {
      return defaultValue;
    }
    if (value === "1/1/0001 12:00 AM MST") {
      return defaultValue;
    }
    value = toDate(value, {
      timeZone: "America/Boise",
    });
  }
  const date = formatTz(value, timeFormat);
  if (date === "1/1/0001") {
    return defaultValue;
  }
  return date;
}

export interface IDateFormattedParameters {
  start?: string;
  stop?: string;
}

export interface IDateParameters {
  start?: Date;
  stop?: Date;
}

/**
 * Adjusts the requested times to the start and end of the day for start and stop respectively.
 * It also makes the end date request equal to now if the date is greater than now.
 * Maxing out the date is done so that paging will operate as expected.
 * @param {Date | undefined} start The start date for the search query
 * @param {Date | undefined} stop The end date for the search query
 * @param {boolean} allowUndefinedDates If dates are not set, they will not default to the current date
 * @returns {IDateParameters} The adjusted dates based on the passed in start and stop parameters
 */
export function getAdjustedTimes(
  start: Date | undefined,
  stop: Date | undefined,
  allowUndefinedDates?: boolean
): IDateParameters {
  const now = new Date();
  let startDate = allowUndefinedDates ? undefined : now;
  let endDate = allowUndefinedDates ? undefined : now;
  if (start) {
    startDate = startOfDay(start);
  }
  if (stop) {
    endDate = endOfDay(stop);
  }
  if (startDate && isAfter(startDate, now)) {
    console.warn(`${start} is in future`);
    startDate = startOfDay(now);
  }
  if (endDate && isAfter(endDate, now)) {
    endDate = now;
  }
  return {
    start: startDate,
    stop: endDate,
  };
}

/**
 * Adjusts the date selection to start and end of day and transforms them from MM/DD/YYYY HH:mm:ss to YYYY-MM-DD HH:mm:ss
 * @param {Date | undefined} start The start date
 * @param {Date | undefined} stop The end date
 * @param {boolean} allowUndefinedDates If dates are not set, they will not default to the current date
 * @returns {IDateFormattedParameters} The adjusted and formatted dates
 */
export function getAdjustedTimesFormatted(
  start: Date | undefined,
  stop: Date | undefined,
  allowUndefinedDates?: boolean
): IDateFormattedParameters {
  const adjustedTimes = getAdjustedTimes(start, stop, allowUndefinedDates);
  const returnValue: IDateFormattedParameters = {};
  if (adjustedTimes.start) returnValue.start = adjustedTimes.start?.toISOString();
  if (adjustedTimes.stop) returnValue.stop = adjustedTimes.stop?.toISOString();
  return returnValue;
}

/**
 * Adjusts the date selection to start and end of day and transforms them from MM/DD/YYYY HH:mm:ss to YYYY-MM-DD HH:mm:ss
 * @param {Date} start The start date
 * @param {Date} stop The end date
 * @returns {IDateFormattedParameters} The adjusted and formatted dates
 */
export function getTimesFormatted(start?: Date, stop?: Date): IDateFormattedParameters {
  return {
    start: start ? formatTz(start, formatDateOut) : undefined,
    stop: stop ? formatTz(stop, formatDateOut) : undefined,
  };
}
